export const paths = {
  internal: '/internal',
  index: '/',
  auth: {
    login: '/auth/login',
    register: (searchParams?: Nullable<string>) =>
      searchParams ? `/auth/register?${searchParams}` : '/auth/register',
    forgotPassword: '/auth/forgot-password',
    confirmRegister: '/auth/confirm-register',
  },
  booking: {
    index: (storeId: string) => `/booking/${storeId}`,
  },
  notFound: '/404',
  remus: {
    index: '/notes',
    consultations: '/notes/consultations',
    analytics: '/notes/analytics',
    settings: '/notes/settings',
    register: '/notes/register',
    consultation: (id: string) => `/notes/consultations/${id}`,
    consultationThreaded: (id: string) => `/notes/consultations/threaded/${id}`,
  },
};
