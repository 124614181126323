import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      title: 'A simple example',
      subtitle: "It's a blank-page. Start to build your'e page from here.",
      error_with_status: 'An error occurred on server',
      404: 'This page could not be found.',
      '404_subtitle':
        'You can either stay and chill here, or go back to the beginning.',
      back: 'back to home',
      error_without_status: 'An error occurred on the server',
      contact_title: 'Free Project Quote',
      contact_title2: 'Say Hello to Us',
      contact_subtitle:
        'Do you have any questions? Are you ready to reduce costs and create new revenue? Or you simply want to say hi? Drop us a message.',
      form_name: 'What is your name? *',
      form_email: 'What it your email? *',
      form_phone: 'What is your phone number?',
      form_company: 'What is your company?',
      form_message: 'Write your message here',
      form_terms: 'I have read and accept the',
      form_privacy: 'Privacy Policy *',
      form_terms_and_conditions: ' and Terms & Conditions *',
      form_send: 'Send Message',
      login: 'Log In',
      login_create: 'Create new account',
      login_or: 'Or login with email',
      login_email: 'Email',
      login_password: 'Password',
      login_remember: 'Remember',
      login_forgot: 'Forgot Password',
      continue: 'Continue',
      login_title: 'Welcome back',
      login_subtitle: 'Please login to continue',
      register: 'Register',
      register_already: 'Already have account?',
      register_or: 'Or register with email',
      register_name: 'Name? *',
      register_email: 'Email? *',
      register_password: 'Password',
      register_confirm: 'Confirm Password',
      register_title: 'Nice to meet you :)',
      register_subtitle: 'Just register to join with us',
      accept: 'Accept',
      en: 'English',
      de: 'Deutsch',
      zh: '简体中文',
      pt: '󠁥󠁮󠁧󠁿Português',
      id: '󠁥󠁮󠁧󠁿Bahasa Indonesia',
      ar: '󠁥󠁮󠁧󠁿العربيّة',
      notif_msg:
        'This is an example of a message that is commonly used for important information to visitors or users. Please click the button beside to close this message.',
      'saas-landing': {
        header_home: 'Home',
        header_features: 'Features',
        header_web: 'Web',
        header_app: 'App',

        header_login: 'Log In',
        header_register: 'register',
        contact_us: 'Contact Us',
        header_language: 'language',
        header_theme: 'Theme mode',
        header_dark: 'dark',
        header_light: 'light',
        header_feature: 'web',
        header_showcase: 'app',
        header_testimonials: 'testimonials',
        header_pricing: 'pricing and plan',
        header_faq: 'faq',
        header_contact: 'contact',
        banner_title: 'AI Powered Petcare',
        banner_subtitle:
          'Online booking. Automated reminders. Smart agenda management. User friendly app. Pet services platform. AI chat. And much more...',
        banner_watchvideo: 'Watch Video',
        getstarted: 'Schedule a call',
        counter_month: 'Month',
        counter_free: 'Free Trial',
        counter_users: 'Active Users',
        counter_providers: 'Providers',

        feature_title1: 'Reclaim up to',
        feature_title1strong: ' 10 hours ',
        feature_title1second:
          'every week - allowing your teams to do what they do best',
        feature_desc1:
          'Make your pet service tasks simpler. Our platform takes care of the routine stuff, so your team can spend more time looking after pets and connecting with parents.',

        see_detail: 'See Detail',

        feature_title2: 'Reduce missed appointments by up to',
        feature_title2strong: ' 33% ',
        feature_title2second:
          '- ensuring customers who need to be seen, can be seen',
        feature_desc2:
          'Stay connected and prepared with our gentle reminders. We help you keep schedules efficient, making sure every pet gets the attention and care it deserves.',

        feature_title3: 'Decrease missed calls by up to',
        feature_title3strong: ' 75% ',
        feature_title3second: "- ensuring you're always there for your client",
        feature_desc3:
          'Every call is a chance to make a difference for a pet and its parent. Lupa Pets enhances your communication channels, ensuring each client feels heard and valued.',

        testi_title: 'What Our Customers',
        testi_titlestrong: 'Say',
        pricing_title: 'Pricing & Plan',
        pricing_subtitle:
          'The best value designed for your workplace, starting at just $24/user/month.',
        faq_subtitle:
          'Have a question? Check out our frequently asked questions to find your answer.',
        caption_news: 'news',
        caption_event: 'event',
        news_readmore: 'read more',
        footer_waiting: 'What are you waiting for?',
      },
      showcase: {
        showcase_title: 'An app your customers will love. We guarantee it!',
      },
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
