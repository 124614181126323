import { createComponents } from './create-components';
import { createTypography } from './create-typography';

// Here we do not modify the "palette" and "shadows" because "light" and "dark" mode
// may have different values.

export const createOptions = (): any => {
  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
    components: createComponents(),
    direction: 'ltr',
    shape: {
      borderRadius: 8,
    },
    typography: createTypography(),
    rounded: {
      small: '8px',
      medium: '12px',
      big: '20px',
    },
  };
};
