import axios from 'axios';

import { globalSingleton } from '../singletons/globalSingleton';

export const API_ENDPOINT =
  document.location.hostname === 'localhost'
    ? 'http://localhost:3000'
    : document.location.hostname === 'staging.lupapets.com'
      ? 'https://staging.lupapets.com'
      : 'https://lupapets.com';

const api = axios.create({
  baseURL: API_ENDPOINT,
});

api.interceptors.request.use(
  (config) => {
    const token = globalSingleton.accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export default api;
