import React from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Sentry from '@sentry/react';
import { enGB } from 'date-fns/locale';
import { enableMapSet as immerEnableMapSet } from 'immer';
import { AuthProvider } from 'lupa-shared-ui/src/contexts/auth/AuthProvider';
import { AuthTokenRefresher } from 'lupa-shared-ui/src/contexts/auth/AuthTokenRefresher';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';

import { updateGlobalSingletonWithSession } from '~/singletons/globalSingleton';

import App from './App';
import './i18n';
import './index.css';
import { API_ENDPOINT } from './lib/axios';
import { getSupabase } from './lib/supabase';
import reportWebVitals from './reportWebVitals';
import { IS_DEV } from './utils/environment-utils';

if (!IS_DEV) {
  ReactGA.initialize('G-524VVQ2FTZ');

  Sentry.init({
    dsn: 'https://ceda59b4f2941344a54fa0f0bd399f03@o4507455239553024.ingest.de.sentry.io/4507455485378640',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({
        levels: ['error'],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', API_ENDPOINT],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: IS_DEV ? 'development' : 'production',
  });
}

immerEnableMapSet();

const root = ReactDOM.createRoot(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  document.getElementById('root') as HTMLElement,
);

const AppWithProviders = () => (
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
    <AuthProvider
      supabaseProvider={getSupabase}
      sessionUpdateCallback={updateGlobalSingletonWithSession}
    >
      <AuthTokenRefresher />
      <App />
    </AuthProvider>
  </LocalizationProvider>
);

root.render(
  IS_DEV ? (
    <React.StrictMode>
      <AppWithProviders />
    </React.StrictMode>
  ) : (
    <AppWithProviders />
  ),
);

reportWebVitals();
