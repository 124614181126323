import React from 'react';

import { Box, Card, Stack, useMediaQuery } from '@mui/material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { createTheme } from '../theme';

const useStyles = makeStyles({ uniqId: 'form' })((theme) => ({
  mainWrap: {
    position: 'relative',
    width: '100%',
    background: theme.palette.background.default,
    maxWidth: '100vw',
    minHeight: '100vh',
    overflowX: 'clip',
    overflowY: 'clip',
    display: 'flex',
  },
  decoration: {
    position: 'absolute',
    width: 800,
    margin: 'auto',
    top: -300,
    '& svg': {
      fill: theme.palette.primary.main,
      transform: 'scale(2)',
      width: '100%',
      height: '100%',
    },
  },
}));

interface AnimationWrapperProps {
  color?: Nullish<string>;
  displayInCenter?: boolean;
  children: React.ReactNode;
}

export default function AnimationWrapper({
  color,
  displayInCenter = false,
  children,
}: AnimationWrapperProps) {
  const { classes } = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const customTheme = createTheme({
    colorPreset: color || 'purple',
    contrast: 'high',
    paletteMode: 'light',
    responsiveFontSizes: true,
  });

  return (
    <ThemeProvider theme={customTheme}>
      <Box className={classes.mainWrap} sx={{ flex: 1 }} pt={{ xs: 0, md: 4 }}>
        <Stack
          direction='column'
          alignItems='center'
          justifyContent={displayInCenter ? 'center' : 'flex-start'}
          flex={1}
          sx={{
            maxWidth: '100%',
          }}
        >
          {!isSmallScreen && (
            <div className={classes.decoration}>
              <svg
                width={isSmallScreen ? '500px' : '900px'}
                height={isSmallScreen ? '1000px' : '1200px'}
                viewBox={isSmallScreen ? '0 -0 900 250' : '0 -20 900 250'}
                version='1.1'
              >
                <defs>
                  <linearGradient
                    x1='78.2441494%'
                    y1='65.8737759%'
                    x2='10.5892887%'
                    y2='33.8596367%'
                    id='linearGradient-1'
                  >
                    <stop
                      stopColor={customTheme.palette.primary.dark}
                      offset='0%'
                    />
                    <stop
                      stopColor={customTheme.palette.primary.main}
                      offset='100%'
                    />
                  </linearGradient>
                </defs>
                <g stroke='none' strokeWidth='0' fill='none' fillRule='evenodd'>
                  <path
                    d='M442.972909,617.331576 C569.290851,617.331576 618.618612,525.937324 804.142458,549.304771 C989.666303,572.672218 872.7227,109.743835 732.652282,54.307977 C592.581863,-1.12788075 538.308155,61.549598 304.148084,8.36113994 C69.9880137,-44.8273182 0,167.6782 0,308.489881 C0,450.379879 177.014996,617.331576 442.972909,617.331576 Z'
                    id='Oval'
                    fill='url(#linearGradient-1)'
                  />
                </g>
              </svg>
            </div>
          )}

          <Box
            px={{
              md: 2,
            }}
            py={{
              md: 6,
            }}
          >
            <Card
              elevation={0}
              sx={{
                width: '100%',
                zIndex: 1000,
                position: 'relative',
                boxShadow: isSmallScreen
                  ? ''
                  : '0px 0px 10px 10px rgba(0, 0, 0, 0.3)',
                borderRadius: isSmallScreen ? 0 : 'auto',
              }}
            >
              {children}
            </Card>
          </Box>
        </Stack>
      </Box>
    </ThemeProvider>
  );
}
