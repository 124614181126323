import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

interface RedirectComponentProps {
  to?: string;
}

export default function RedirectComponent({ to }: RedirectComponentProps) {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const redirectUri = to ?? params.get('redirect_uri');

    if (redirectUri) {
      window.location.href = redirectUri;
    } else {
      console.error('No redirect_uri provided');
      // Handle this case as appropriate for your application.
    }
  }, [location]);

  return null;
}
