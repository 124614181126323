import React from 'react';

import { Stack, Typography } from '@mui/material';

export default function ErrorBoundaryFallback() {
  return (
    <Stack
      direction='column'
      alignItems='center'
      justifyContent='center'
      flexGrow={1}
      mt={8}
      sx={{ color: 'black' }}
    >
      <img
        src='/logo.png'
        alt='logo'
        style={{ width: 80, height: 80, borderRadius: 16 }}
      />

      <Typography variant='h6'>Something went wrong :(</Typography>
      <Typography variant='body2'>Please refresh.</Typography>
    </Stack>
  );
}
